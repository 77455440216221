/* eslint-disable max-lines */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Loader from '@scandipwa/scandipwa/src/component/Loader/Loader.component';
import CmsPage from './CmsPage.component';
import { connect } from 'react-redux';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { updateMeta } from 'Store/Meta/Meta.action';
import { waitForElm } from 'Component/Config';
import history from 'Util/History';
import { LOADING_TIME } from './CmsPage.config';
import { Page } from 'Component/Header/Header.config';
import { updateCmsPage } from 'Store/Cms/Cms.action';
import CmsDispatcher from 'Store/Cms/Cms.dispatcher';
import {
    CmsPageContainer as SourceCmsPageContainer,
    // mapDispatchToProps,
    // mapStateToProps
} from 'SourceRoute/CmsPage/CmsPage.container';
import { isMobile } from 'Util/mcMobileCheck';
import { getUrlParam, isHomePageUrl } from 'Util/Url';
import { debounce } from 'Util/Request/Debounce';

// export {
//     mapStateToProps,
//     mapDispatchToProps
// };

export const mapStateToProps = (state) => (
    {
        isOffline: state.OfflineReducer.isOffline,
        cmsPage: state.CmsReducer.cmsPage,
        isLoading: state.CmsReducer.isLoading,
    });

/** @namespace Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.updateWithCmsPage(breadcrumbs, dispatch),
    setHeaderState: (stateName) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, stateName)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    toggleBreadcrumbs: (isActive) => {
        BreadcrumbsDispatcher.update([], dispatch);
        dispatch(toggleBreadcrumbs(isActive));
    },
    requestPage: (options) => CmsDispatcher.handleData(dispatch, options),
    updateWithInitialCmsPage: (cmsPage) => {
        dispatch(updateCmsPage(cmsPage));
    },
});

/** @namespace Pwa/Route/CmsPage/Container/CmsPageContainer */
export class CmsPageContainer extends SourceCmsPageContainer {
    state = {
        // isLoading: true,
        isPageLoaded: false,
        openToggle: false,
        checkOut1: false,
        checkOut2: false,
        checkOut3: false,
        checkOut4: false,
        checkOut5: false,
        checkOut6: false,
        checkOut7: false,
        isLoading2: false,
        isLoadingHide: false,
        pageIds: -1,
        pageIdentifiers: '',
        isOnlyPlaceholder: false,
        isBreadcrumbsActive: true
    };

    __construct() {
        this.setOfflineNoticeSize = this.setOfflineNoticeSize.bind(this);

        this.updateBreadcrumbs();
    }

    componentDidMount() {
        const {
            isOffline,
            isOnlyPlaceholder,
            isLoading,
        } = this.props;
        const { isPrefetchValueUsed } = window;

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }
        //this.requestPage()
        if (!isOnlyPlaceholder && isPrefetchValueUsed) {
            this.requestPage();
        }
        if (window.location.pathname === '/cookies') {
            this.setState({ isLoadingHide: true });
            const reloadCount = sessionStorage.getItem('reloadCount');
            if (reloadCount < 1) {
                sessionStorage.setItem('reloadCount', String(reloadCount + 1));
                window.location.reload();
            } else {
                this.setState({ isLoadingHide: false });
                sessionStorage.removeItem('reloadCount');
            }
        }

        if (window.location.pathname === '/faq') {
            // DATALAYER FAQ
            this.setState({ isLoading2: true });
            setTimeout(() => {
                const elem = [];
                const n = 7;
                // eslint-disable-next-line fp/no-let
                for (let i = 0; i < n; i++) {
                    const tmp = document.querySelector(`#checkOut${i + 1}`);
                    if (tmp) {
                        elem.push(tmp);
                    }
                }
                const sottoTag = Array.from(document.querySelectorAll('.mc-accordion-inside-label'));
                this.setState({ isLoading2: false });
                sottoTag.map((elem) => {
                    // eslint-disable-next-line fp/no-let
                    let open = false;
                    elem.addEventListener('click', () => {
                        // EVENTO GA4
                        window?.dataLayer?.push({
                            event: 'faq',
                            faq_action: open ? 'close' : 'open',
                            faq_name: elem.innerText
                        });
                        open = !open;
                    });
                });
                elem.map((a) => {
                    a.addEventListener('click', () => {
                        // EVENTO GA4
                        window?.dataLayer?.push({
                            event: 'faq',
                            faq_action: this.state[a.id] ? 'close' : 'open',
                            faq_name: this.filterFaqLabel(a.id)
                        });
                        this.filterFaqState(a.id);
                    });
                });
                // eslint-disable-next-line no-magic-numbers
            }, 2000);
            //this.setState({ isLoadingHide: false });
            //this.setState({ isLoading: false });
        }

        if (window.location.pathname === '/progettare-cucina-su-misura-consulenza') {
            this.setState({ isLoading2: true });
            setTimeout(() => {
                const pv = document.querySelector('.mc-card-link-group')?.firstChild?.firstChild;
                const elem = Array.from(document.querySelectorAll('.mc-card-link a.abtn'));
                this.setState({ isLoading2: false });
                if (pv) {
                    pv.addEventListener('click', () => {
                        // EVENTO GA4
                        window?.dataLayer?.push({
                            event: 'book_appointment_start',
                            category: 'book_appointment',
                            action: 'start',
                            label: 'punto_vendita',
                            start_point: 'punto_vendita'
                        });
                    });
                }
                // eslint-disable-next-line array-callback-return
                elem.map((a) => {
                    a.addEventListener('click', () => {
                        // EVENTO GA4
                        window?.dataLayer?.push({
                            event: 'book_appointment_start',
                            category: 'book_appointment',
                            action: 'start',
                            label: this.getAppLabel(a.innerText),
                            start_point: this.getAppLabel(a.innerText)
                        });
                    });
                });
                // eslint-disable-next-line no-magic-numbers
            }, 2000);
        }

        if (window.location.pathname === '/black-friday') {
            this.scrollToService();
        }

        super.componentDidMount();
    }

    async scrollToService() {
        const cta = await waitForElm('#cta_bf_service');
        if (cta) {
            cta.addEventListener('click', async () => {
                const box = await waitForElm('#box_bf_service');
                if (box) {
                    const boxRect = box.getBoundingClientRect();

                    // eslint-disable-next-line no-magic-numbers
                    const headerHeight = isMobile() ? 10 : 170;
                    const targetPosition = boxRect.top - headerHeight;

                    // Scroll to the target position
                    window.scrollBy({
                        top: targetPosition,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            });
        }
    }

    getAppLabel(label) {
        switch (label) {
            case 'Prenota una consulenza':
                return 'videochiamata';
            case 'Scopri di più':
                return 'dolce_casa';
            default:
                return '';
        }
    }

    updateBreadcrumbs() {
        const {
            toggleBreadcrumbs,
            isBreadcrumbsActive,
        } = this.props;

        toggleBreadcrumbs(isBreadcrumbsActive);
    }

    setOfflineNoticeSize() {
        const { setBigOfflineNotice, isLoading } = this.props;

        if (isLoading) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    }


    onPageLoad() {
        const {
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
        } = this.props;
        const { cmsPage } = this.props;
        const { location: { pathname } } = history;
        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
        } = cmsPage;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(cmsPage);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: Page.CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack(),
            });
        }
    }

    getRequestQueryParams() {
        const {
            match,
            pageIdentifiers: identifier,
            pageIds: id,
        } = this.props;
        const { location } = history;

        // if (identifier) {
        //     return { identifier };
        // }

        // if (id !== -1) {
        //     return { id };
        // }

        const urlKey = getUrlParam(match, location);

        return {
            identifier: urlKey,
        };
    }

    requestPage() {
        const { requestPage, updateWithInitialCmsPage } = this.props;
        const params = this.getRequestQueryParams();
        const { id, identifier = '' } = params;
        const {
            actionName: {
                id: pageId = null,
                cmsPage: { identifier: pageIdentifier = null } = {},
                cmsPage = {},
            } = {},
        } = window;

        if (!id && !identifier) {
            return;
        }

        // vvv check if cms page was already loaded from action
        if (
            id === pageId
            || identifier.replace(/^\/+/, '') === pageIdentifier
        ) {
            updateWithInitialCmsPage(cmsPage);

            return;
        }

        requestPage(params);
    }

    componentDidUpdate(prevProps) {
        const {
            currentUrl,
            pageIdentifiers,
            pageIds,
            cmsPage,
            isLoading,
        } = this.props;

        const {
            currentUrl: prevCurrentUrl,
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds,
            cmsPage: prevCmsPage,
        } = prevProps;

        const { isPrefetchValueUsed } = window;

        if (
            ((currentUrl !== prevCurrentUrl
                || pageIds !== prevPageIds
                || pageIdentifiers !== prevPageIdentifiers)
                && !isLoading)
            && !isPrefetchValueUsed
        ) {
            this.requestPage();
        }

        if (JSON.stringify(cmsPage) !== JSON.stringify(prevCmsPage) || isPrefetchValueUsed) {
            this.onPageLoad();
        }

        if (this.props.location !== prevProps.location && window.location.pathname === '/cookies') {
            this.setState({ isLoadingHide: true });
            const reloadCount = sessionStorage.getItem('reloadCount');
            if (reloadCount < 1) {
                sessionStorage.setItem('reloadCount', String(reloadCount + 1));
                window.location.reload();
            } else {
                this.setState({ isLoadingHide: false });
                sessionStorage.removeItem('reloadCount');
            }
        }
        // if (window.location.pathname === '/faq') {
        //     this.requestPage()
        // }
        super.componentDidUpdate(prevProps);
    }

    componentWillUnmount() {
        window.isPrefetchValueUsed = false;
        if (window.location.pathname === '/faq') {
            const elem = [];
            const n = 7;
            // eslint-disable-next-line fp/no-let
            for (let i = 0; i < n; i++) {
                const tmp = document.querySelector(`#checkOut${i + 1}`);
                if (tmp) {
                    elem.push(tmp);
                }
            }
            // eslint-disable-next-line array-callback-return
            elem.map((a) => {
                a.removeEventListener('click', () => {
                    // EVENTO GA4
                    window?.dataLayer?.push({
                        event: 'faq',
                        faq_action: this.state[a.id] ? 'close' : 'open',
                        faq_name: this.filterFaqLabel(a.id)
                    });
                    this.filterFaqState(a.id);
                });
            });
        }
    }

    filterFaqState(id) {
        switch (id) {
            case 'checkOut1':
                this.setState((prevState) => ({ checkOut1: !prevState.checkOut1 }));
                break;
            case 'checkOut2':
                this.setState((prevState) => ({ checkOut2: !prevState.checkOut2 }));
                break;
            case 'checkOut3':
                this.setState((prevState) => ({ checkOut3: !prevState.checkOut3 }));
                break;
            case 'checkOut4':
                this.setState((prevState) => ({ checkOut4: !prevState.checkOut4 }));
                break;
            case 'checkOut5':
                this.setState((prevState) => ({ checkOut5: !prevState.checkOut5 }));
                break;
            case 'checkOut6':
                this.setState((prevState) => ({ checkOut6: !prevState.checkOut6 }));
                break;
            case 'checkOut7':
                this.setState((prevState) => ({ checkOut7: !prevState.checkOut7 }));
                break;
            default:
                break;
        }
    }

    filterFaqLabel(id) {
        switch (id) {
            case 'checkOut1':
                return 'Come acquistare';
            case 'checkOut2':
                return 'Informazioni sui prodotti';
            case 'checkOut3':
                return 'I nostri servizi';
            case 'checkOut4':
                return 'Modalità di consegna';
            case 'checkOut5':
                return 'Area Fai Da Te';
            case 'checkOut6':
                return 'Modalità di pagamento';
            case 'checkOut7':
                return 'Consegna Gold';
            default:
                return '';
        }
    }

    render() {
        const { isLoading2, isLoadingHide } = this.state;
        return (
            <>
                <Loader isLoading={isLoading2 || isLoadingHide} />
                {!isLoadingHide && (
                    <CmsPage
                        {...this.props}
                        {...this.state}
                        {...this.getRequestQueryParams()}
                    />
                )}
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
