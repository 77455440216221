// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Pwa/Component/ResetScroll/Container/ResetScrollContainer */
export class ResetScrollContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

export default ResetScrollContainer;
