/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
import { PureComponent } from 'react';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import "./CmsPage.override.style.scss";
import { ReactElement } from 'Type/Common.type';

import { CmsPageComponentProps } from './CmsPage.type';

import './CmsPage.style';

/** @namespace Route/CmsPage/Component */
export class CmsPageComponent extends PureComponent {
    static defaultProps = {
        isBreadcrumbsActive: true,
    };



    renderHeading() {
        const { cmsPage: { content_heading }, isLoading } = this.props;

        if (!content_heading || isLoading) {
            return null;
        }

        return (
            <h1 block="CmsPage" elem="Heading">
                <TextPlaceholder content={content_heading} />
            </h1>
        );
    }

    renderContent() {
        const {
            isLoading,
            cmsPage: { content },
        } = this.props;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!content) {
            return null;
        }

        return <Html content={content} />;
    }

    render() {
        const {
            cmsPage,
            isBreadcrumbsActive,
            isLoading,
            identifier
        } = this.props;
        const { page_width, title, content } = cmsPage;

        if (!isLoading && !title && !content) {
            return <NoMatch />;
        }

        return (
            <main
                block="CmsPage"
                className={identifier}
                mods={{ isBreadcrumbsHidden: !isBreadcrumbsActive }}
            >
                <div block="CmsPage" elem="Wrapper" mods={{ page_width }}>
                    {this.renderHeading()}
                    <div block="CmsPage" elem="Content">
                        {this.renderContent()}
                    </div>
                </div>
            </main>
        );
    }
}

export default CmsPageComponent;