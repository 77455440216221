import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CmsBlock from 'Component/CmsBlock';
// import HomepageCTASlider from 'Component/HomepageCTASlider';
import HomepageSlider from 'Component/HomepageSlider';
import IfStore from 'Component/IfStore';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import ResetScroll from 'Component/ResetScroll';
import Swogo from 'Component/Swogo/Swogo.container';
export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */ 'Store/Meta/Meta.dispatcher'
);
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/HomePage/HomePage.container';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { autoReloadProducts } from 'Util/reloadProductsEvent';

import './HomePage.override.style.scss';

/** @namespace Pwa/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: (isVisible) => dispatch(toggleBreadcrumbs(isVisible)),
    updateMeta: (meta) => MetaDispatcher.then(
        () => dispatch(updateMeta(meta))
    )
});

/** @namespace Pwa/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    meta: {
        keywords: state.ConfigReducer.default_keywords,
        title: state.ConfigReducer.default_title,
        description: state.ConfigReducer.default_description
    }
});

/** @namespace Pwa/Route/HomePage/Container/HomePageContainer */
export class HomePageContainer extends SourceHomePageContainer {
    static propTypes = {
        ...SourceHomePageContainer.propTypes,
        updateMeta: PropTypes.func.isRequired,
        meta: PropTypes.shape({
            keywords: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired
        })
    };

    updateHomepageMeta() {
        const { updateMeta, meta } = this.props;
        updateMeta({
            ...meta,
            status_code: 200
        });
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateHomepageMeta();

        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);
        this.reloadTimer = autoReloadProducts();
    }

    componentWillUnmount() {
        clearTimeout(this.reloadTimer);
    }

    render() {
        return (
            <>
                <ResetScroll />
                <div block="HomePage">
                    <div className="CmsPage-Wrapper">
                        <div className="CmsPage-Content">
                            <RenderWhenVisible>
                                <CmsBlock
                                  placeHolderSize={ { width: '100vw', height: '43vw' } }
                                  identifier="slider_home"
                                  blockType="SliderHome"
                                />
                            </RenderWhenVisible>
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                    { <HomepageSlider flashSaleCode="carousel" /> }
                                </IfStore>
                                <IfStore storeCode="spain">
                                    { <HomepageSlider flashSaleCode="carousel-es" /> }
                                </IfStore>
                            </RenderWhenVisible>
                            { /* black friday banner 2021 */ }
                            { /*    <RenderWhenVisible> */ }
                            { /*        <IfStore storeCode="default"> */ }
                            { /*        <CmsBlock */ }
                            { /*          placeHolderSize={ { width: '100vw', height: '43vw' } } */ }
                            { /*          identifier="black-friday-homepage" */ }
                            { /*          blockType="BlackFriday" */ }
                            { /*        /> */ }
                            { /*        </IfStore> */ }
                            { /*    </RenderWhenVisible> */ }
                            <RenderWhenVisible>
                                <RenderWhenVisible>
                                    <IfStore storeCode="spain">
                                        <CmsBlock
                                          placeHolderSize={ { width: '100vw', height: '500px' } }
                                          identifier="home_cta_0_april"
                                        />
                                    </IfStore>
                                </RenderWhenVisible>
                                <IfStore storeCode="default">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_1"
                                    />
                                </IfStore>
                                <IfStore storeCode="spain">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_1_april"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            <Swogo bundleId={ 2 } />
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cat_1"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cat_3"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_2"
                                    />
                                </IfStore>
                                <IfStore storeCode="spain">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_3_april"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                <CmsBlock
                                  placeHolderSize={ { width: '100vw', height: '500px' } }
                                  identifier="home_cat_2"
                                />
                                </IfStore>
                                <IfStore storeCode="spain">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_4_april"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            <RenderWhenVisible>
                                <IfStore storeCode="spain">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_5"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            {/* <RenderWhenVisible>
                                <IfStore storeCode="spain">
                                    <HomepageCTASlider />
                                </IfStore>
                            </RenderWhenVisible> */}
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                    <CmsBlock
                                      identifier="home_cta_dolce_casa"
                                    />
                                </IfStore>
                                <IfStore storeCode="spain">
                                    <CmsBlock
                                      placeHolderSize={ { width: '100vw', height: '500px' } }
                                      identifier="home_cta_5_april"
                                    />
                                </IfStore>
                            </RenderWhenVisible>
                            { /* <RenderWhenVisible placeHolderSize={ { width: '100vw', height: '300vw' } }>
                                <CmsBlock
                                  identifier="home_cta_consigli"
                                />
                            </RenderWhenVisible> */ }
                            <RenderWhenVisible>
                                <IfStore storeCode="default">
                                    <div className="CmsBlock_fullWidth">
                                        <CmsBlock
                                          placeHolderSize={ { width: '100vw', height: '500px' } }
                                          identifier="home_cta_cataloghi"
                                          fullWidth
                                        />
                                    </div>
                                </IfStore>
                                <IfStore storeCode="spain">
                                    <div className="CmsBlock_fullWidth">
                                        <CmsBlock
                                          placeHolderSize={ { width: '100vw', height: '500px' } }
                                          identifier="home_cta_cataloghi"
                                          fullWidth
                                        />
                                    </div>
                                </IfStore>
                            </RenderWhenVisible>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
