import { PureComponent } from 'react';

import Link from 'Component/Link';
import ProductSlider from 'Component/ProductSlider/ProductSlider.component';
import { SliderProductsType } from 'Type/SliderProductsType';

import './HomepageSlider.style';

/** @namespace Pwa/Component/HomepageSlider/HomepageSliderBlackFriday/Component/HomepageSliderBlackFridayComponent */
export class HomepageSliderBlackFridayComponent extends PureComponent {
    static propTypes = {
        blackFridayCounterCarouselProducts: SliderProductsType.isRequired
    };

    renderTitle() {
        return (
            <div className="row">
                <div className="col-12 title">
                    <h5>{ __('BLACK WEEK: prodotti fino al 60% di SCONTO') }</h5>
                    <Link
                      className="Button"
                      block="ViewAll"
                      elem="CTA"
                        // mods={ mods }
                      to="/offerte/fine-serie.html"
                      id="home-sconto_iva-cta"
                    >
                        <span>
                            { __('SCOPRILI TUTTI') }
                        </span>
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const { blackFridayCounterCarouselProducts } = this.props;
        const config = {
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                },
                1280: {
                    slidesPerView: 4
                },
                1400: {
                    slidesPerView: 4
                },
                1920: {
                    slidesPerView: 4
                }
            },
            theme: 'FlashSales',
            stickers: 'flash_sales_slider'
        };

        return blackFridayCounterCarouselProducts.length > 0 && (
            <div block="HomepageSlider" mods={ { blackFridayCounterCarouselProducts } }>
                { this.renderTitle() }
                <ProductSlider
                  items={ blackFridayCounterCarouselProducts }
                  config={ config }
                  list="Home page products slider"
                  isBlackFriday="true"
                />
            </div>
        );
    }
}

export default HomepageSliderBlackFridayComponent;
