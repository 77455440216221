/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SliderProductsType } from 'Type/SliderProductsType';
import { bindReloadProducts, unbindReloadProducts } from 'Util/reloadProductsEvent';

import HomepageSlider from './HomepageSlider.component';
import HomepageSliderBlackFriday from './HomepageSliderBlackFriday.component';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

export const HomepageSliderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/HomepageSlider/HomepageSlider.dispatcher'
);

export const BlackFridayCounterCarouselDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/BlackFridayCounterCarousel/BlackFridayCounterCarousel.dispatcher'
);

export const McRealTimePromoDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McRealTimePromo/McRealTimePromo.dispatcher'
);

export const listName = 'Home page products slider';

/** @namespace Pwa/Component/HomepageSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    sliderProducts: state.HomepageSliderReducer.sliderProducts,
    blackFridayCounterCarouselProducts: state.BlackFridayCounterCarouselReducer.sliderProducts,
    storeCode: state.ConfigReducer.code
});

/** @namespace Pwa/Component/HomepageSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    pushProductImpressions: (products, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductImpressions(dispatch, products, list)
    ),
    loadSliderProducts: (flashSaleCode) => HomepageSliderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, flashSaleCode)
    ),
    loadBlackFridayCounterCarouselProducts: (flashSaleCode) => BlackFridayCounterCarouselDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, flashSaleCode)
    ),
    updatePromo: (skus, storeCode) => McRealTimePromoDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updatePromo(skus, storeCode, dispatch)
    )
});

/** @namespace Pwa/Component/HomepageSlider/Container/HomepageSliderContainer */
export class HomepageSliderContainer extends PureComponent {
    static propTypes = {
        flashSaleCode: PropTypes.string.isRequired,
        sliderProducts: SliderProductsType.isRequired,
        storeCode: PropTypes.string.isRequired,
        pushProductImpressions: PropTypes.func.isRequired,
        loadSliderProducts: PropTypes.func.isRequired,
        updatePromo: PropTypes.func.isRequired,
        loadBlackFridayCounterCarouselProducts: PropTypes.func.isRequired,
        blackFridayCounterCarouselProducts: SliderProductsType.isRequired
    };

    containerFunctions = {

    };

    componentDidMount() {
        const { loadSliderProducts, flashSaleCode } = this.props;
        loadSliderProducts(flashSaleCode);

        const { loadBlackFridayCounterCarouselProducts } = this.props;
        loadBlackFridayCounterCarouselProducts(flashSaleCode);
        // eslint-disable-next-line no-magic-numbers
        this.relaodTimenout = bindReloadProducts(this.reloadProducts);

        this.refreshInterval = setInterval(() => this.updatePromoPage(), 30000);
    }

    componentDidUpdate(prevProps) {
        const { sliderProducts: newSliderProducts, pushProductImpressions } = this.props;
        const { sliderProducts: oldSliderProducts } = prevProps;

        const { sku: oldFirstSku } = oldSliderProducts[0] || {};
        const { sku: newFirstSku } = newSliderProducts[0] || {};
        if (newFirstSku !== oldFirstSku) {
            pushProductImpressions(newSliderProducts, listName);
            this.updatePromoPage();
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        unbindReloadProducts(this.reloadProducts);
    }

    reloadProducts = (_e) => {
        const { loadSliderProducts, loadBlackFridayCounterCarouselProducts, flashSaleCode } = this.props;
        loadSliderProducts(flashSaleCode);
        loadBlackFridayCounterCarouselProducts(flashSaleCode);
    };

    updatePromoPage = () => {
        const { sliderProducts, storeCode, updatePromo } = this.props;
        const skus = sliderProducts.map((item) => item.sku);
        if (skus.length > 0) {
            updatePromo(skus, storeCode);
        }
    };

    containerProps = () => {
        const { sliderProducts, storeCode, blackFridayCounterCarouselProducts } = this.props;
        return {
            sliderProducts,
            storeCode,
            blackFridayCounterCarouselProducts
        };
    };

    render() {
        const { blackFridayCounterCarouselProducts } = this.props;
        if (Array.isArray(blackFridayCounterCarouselProducts) && (blackFridayCounterCarouselProducts.length > 0)) {
            return (
                <HomepageSliderBlackFriday
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
            );
        }

        return (
            <HomepageSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomepageSliderContainer);
