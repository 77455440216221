/* eslint-disable consistent-return */
/** @namespace Pwa/Component/Config/waitForElm */
export const waitForElm = (selector) => new Promise((resolve) => {
    if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
});
